// MQTT配置信息

const MQTT_OPTIONS = {
	connectTimeout: 5000,
	clientId: 'app_wechat'+Math.random().toString(16).substr(2,8),
	username: 'sudp',
	password: 'sudp@ahad',
	clean: false
}

export default MQTT_OPTIONS;